
<template>
  <b-container fluid>
     <iq-card>
         <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('warehouseInfoService.stockProduct') }}</h4>
      </template>
         <template v-slot:body>
        <b-overlay v-if="authUser.warehouse_id > 0">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-form-group
                class="row"
                label-cols-sm="3"
                :label="$t('warehouse_config.warehouse_name')"
                label-for="office_code"
                >
                <b-form-select
                    plain
                    v-model="search.warehouse_id"
                    :options="warehouseList"
                    id="commodity_group_id"
                    >
                    <template v-slot:first>
                        <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                    </template>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
                <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </b-overlay>
        <b-overlay v-if="authUser.warehouse_id === 0 || authUser.warehouse_id == null">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
        <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
        <b-row v-if="!authUser.warehouse_id">
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Region" vid="region_id" rules="required|min_value:1">
               <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('warehouse_config.region')"
            label-for="region_id"
            slot-scope="{ valid, errors }"
            ><template v-slot:label>
                {{ $t('warehouse_config.region')}} <span class="text-danger">*</span>
            </template>
              <b-form-select
              plain
              v-model="search.region_id"
              :options="regionLists"
              id="region_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
               <ValidationProvider name="District" vid="district_id" rules="required|min_value:1">
            <b-form-group
            class="row"
            label-cols-sm="3"
            :label="$t('warehouse_report.district')"
            label-for="district_id"
            slot-scope="{ valid, errors }"
            ><template v-slot:label>
                {{ $t('warehouse_report.district')}} <span class="text-danger">*</span>
            </template>
              <b-form-select
              plain
              v-model="search.district_id"
              :options="regionDistrictLists"
              id="district_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ districtLoading ? $t('globalTrans.loading') : $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Upazila" vid="upazilla_id" rules="required|min_value:1">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('warehouse_report.upazilla')"
              label-for="upazilla_id"
              slot-scope="{ valid, errors }"
              ><template v-slot:label>
                {{ $t('warehouse_report.upazilla')}} <span class="text-danger">*</span>
            </template>
              <b-form-select
              plain
              v-model="search.upazilla_id"
              :options="upazilaList"
              id="upazilla_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Union" vid="union_id" rules="">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.union')"
              label-for="union_id"
              slot-scope="{ valid, errors }"
              >
              <b-form-select
              plain
              v-model="search.union_id"
              :options="unionList"
              id="union_id"
              :state="errors[0] ? false : (valid ? true : null)"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0" >{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="4">
              <ValidationProvider name="Warehouse" vid="warehouse_id" rules="required|min_value:1">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="warehouse_id"
              :label="$t('warehouse_information.warehouse_name')"
              slot-scope="{ valid, errors }"
              ><template v-slot:label>
                {{ $t('warehouse_information.warehouse_name')}} <span class="text-danger">*</span>
            </template>
              <b-form-select
                plain
                v-model="search.warehouse_id"
                :options="warehouseList"
                id="commodity_group_id"
                v-if="!authUser.warehouse_id"
                :state="errors[0] ? false : (valid ? true : null)"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              <b-form-select
                  plain
                  v-model="search.warehouse_id"
                  :options="warehouseList"
                  id="warehouse_id"
                  v-else
              >
              </b-form-select>
                <div v-if="!authUser.warehouse_id" class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
              </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Crop Type" vid='cropTypeId'>
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="cropTypeId"
              >
              <template v-slot:label>
                    {{ $t('warehouse_config.crop_type')}}
                </template>
              <b-form-select
                plain
                v-model="search.crop_type_id"
                :options="cropTypeList"
                id="cropTypeId"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="4">
            <ValidationProvider name="Crop Name" vid='cropId'>
            <b-form-group
              class="row"
              label-cols-sm="3"
              label-for="cropId"
              >
              <template v-slot:label>
                    {{ $t('warehouse_config.crop_name')}}
                </template>
              <b-form-select
                plain
                v-model="search.crop_id"
                :options="cropList"
                id="cropId"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
              </b-form-group>
            </ValidationProvider>
            </b-col>
            <b-col xs="12" sm="12" md="8">
                <b-button type="submit" variant="primary float-right">{{ $t('globalTrans.submit')}}</b-button>
            </b-col>
            <b-col></b-col>
          </b-row>
          </b-form>
        </ValidationObserver>
        </b-overlay>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card v-show="showHeading">
          <template v-slot:headerTitle>
            <h4 class="card-title">{{$t('warehouseInfoService.stockProduct')}}</h4>
          </template>
                 <template v-slot:headerAction>
                <b-button class="btn_add_new" @click="pdfExport">
                  <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                </b-button>
          </template>
          <template v-slot:body>
              <b-row>
                 <b-col>
                        <list-report-head :base-url="warehouseServiceBaseUrl" :uri="reportHeadingList" :org-id="search.org_id">
                          {{ $t('warehouseInfoService.stockProduct')}}
                        </list-report-head>
                    </b-col>
              </b-row>
            <b-row>
              <b-col lg="6" sm="12">
               <b-form-group
                  class="row"
                  label-cols-sm="3"
                 :label="$t('warehouseInfoService.date')"
                  label-for="Date"
                >
                <div class="active text-dark">{{ currentDate|dateFormat }}</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('warehouse_config.region')"
                  label-for="Region"
                >
                <div class="active text-dark">{{ heading ? getRegion(heading.region_id) : ''  }}</div>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                 :label="$t('org_pro_district.district')"
                  label-for="District"
                >
                 <div class="active text-dark">{{ heading ? getDistrict(heading.district_id) : ''  }}</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('org_pro_upazilla.upazilla')"
                  label-for="Upazilla"
                >
                 <div class="active text-dark">{{ heading ? getUpazila(heading.upazilla_id) : '' }}</div>
                </b-form-group>
              </b-col>
              <b-col lg="6" sm="12">
                <b-form-group
                  class="row"
                  label-cols-sm="3"
                  :label="$t('warehouse_information.warehouse_name')"
                  label-for="Warehouse"
                >
                 <div class="active text-dark">{{ heading ? getWarehouse(heading.warehouse_id) : '' }}</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12" class="table-responsive">
                <b-overlay :show="loader">
                <b-table bordered hover :items="listData" :fields="columns" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                 <template v-slot:cell(farmerId)="data">
                    {{ data.item.farmerId }}
                  </template>
                   <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template v-slot:cell(Quantity)="data">
                     {{ $n(data.item.Quantity, { useGrouping: false }) }}
                    </template>
                  <template v-slot:cell(status)="data">
                    {{ data.item.status ? 'InActive' : 'Active' }}
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button v-b-modal.modal-4 variant=" iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                    <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="ri-delete-bin-line m-0"></i></b-button>
                  </template>
                </b-table>
                </b-overlay>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { warehouseServiceBaseUrl } from '@/config/api_config'
import { getStockProduct, reportHeadingList } from '../../api/routes'
import ExportPdf from '@/Utils/export-pdf'
import ListReportHead from '@/components/custom/ListReportHead.vue'

export default {
  components: {
    ListReportHead
  },
  data () {
    return {
      loader: false,
      listData: [],
      search: {
        region_id: 0,
        district_id: 0,
        upazilla_id: 0,
        union_id: 0,
        warehouse_id: 0,
        crop_type_id: 0,
        crop_id: 0,
        org_id: 13
      },
      currentDate: '',
      showHeading: false,
      regionDistrictLists: [],
      upazilaList: [],
      warehouseList: [],
      cropList: [],
      stockList: [],
      unionList: [],
      FarmerList: [],
      f_name: '',
      f_mobile: '',
      application_id: '',
      orgData: {},
      heading: '',
      organization: [],
      warehouseServiceBaseUrl: warehouseServiceBaseUrl,
      reportHeadingList: reportHeadingList,
      districtLoading: false
    }
  },
  mounted () {
    if (this.isWareHouseUser) {
        this.search.region_id = this.$store.state.warehouse.regionDetailList.find(item => item.district_id === this.authUser.office_detail.district_id).region_id
        this.search.district_id = this.authUser.office_detail.district_id
        this.regionDistrictLists = this.$store.state.commonObj.districtList.filter(doc => doc.value === this.authUser.office_detail.district_id)
        this.search.upazilla_id = this.authUser.office_detail.upazilla_id ?? 0
        this.upazilaList = this.$store.state.commonObj.upazilaList.filter(doc => doc.value === this.authUser.office_detail.upazilla_id)
        this.search.union_id = this.authUser.office_detail.union_id ?? 0
        this.unionList = this.$store.state.commonObj.unionList.filter(doc => doc.value === this.authUser.office_detail.union_id)
        const warehouse = this.$store.state.warehouse.warehouseInfoList.find(doc => doc.office_id === this.authUser.office_detail.id)
        this.search.warehouse_id = warehouse.value
        this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(doc => doc.office_id === this.authUser.office_detail.id)
    }
  },
  computed: {
    cropTypeList: function () {
      return this.$store.state.warehouse.commodityGroupList.filter(item => item.status === 0)
    },
     authUser () {
      return this.$store.state.Auth.authUser
    },
    isWareHouseUser () {
      return this.authUser.warehouse_id
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('warehouseInfoService.cropType'), class: 'text-left' },
          { label: this.$t('warehouseInfoService.cropName'), class: 'text-left' },
          { label: this.$t('warehouseInfoService.quantity'), class: 'text-left' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name_bn' },
          { key: 'commodity_name_bn' },
          { key: 'Quantity' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'commodity_group_name' },
          { key: 'commodity_name' },
          { key: 'Quantity' }
        ]
      }
      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    regionLists: function () {
        let regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
        if (this.isWareHouseUser) {
          regionList = regionList.filter(item => item.value === this.search.region_id)
        }
        return regionList
    }
  },
   watch: {
    'search.region_id': function (newVal, oldVal) {
      if (!this.isWareHouseUser) {
            this.regionDistrictLists = this.getRegionDistrictList(newVal)
        }
    },
    'search.district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.upazilla_id': function (newVal, oldVal) {
    this.unionList = this.getUnionList(newVal)
      this.warehouseList = this.getWarehouseName(newVal, 'UPZ')
    },
    'search.union_id': function (newVal, oldVal) {
      this.warehouseList = this.getWarehouseName(newVal, 'UP')
    },
    'search.crop_type_id': function (newVal, oldVal) {
      this.cropList = this.getCropList(newVal)
    }
  },
  created () {
    if (this.authUser.warehouse_id) {
      const warehouseId = this.authUser.warehouse_id
      this.warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(warehouseInfoItem => warehouseInfoItem.value === warehouseId)
      this.search.warehouse_id = warehouseId
    }
  },
  methods: {
    async searchData () {
      if (this.search.region_id !== 0 || this.search.district_id !== 0 || this.search.upazilla_id !== 0 || this.search.warehouse_id !== 0 || this.search.crop_type_id !== 0 || this.search.crop_id !== 0) {
      this.showHeading = true
      this.organizationData()
      this.loadData()
      }
    },
    async loadData () {
      const today = new Date()
      const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      this.currentDate = date
      const params = Object.assign({}, this.search)
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      await RestApi.getData(warehouseServiceBaseUrl, getStockProduct, params).then(response => {
          if (response.success) {
           this.heading = response.data.warehouse_info
           this.listData = this.formatData(response.data.data)
          } else {
            this.heading = []
            this.listData = []
          }
          this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
      this.loader = false
    },
    async getRegionDistrictList (regionId = null) {
      this.districtLoading = true
      await RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-level/region-district-list').then(response => {
        if (response) {
            const dataObjectTemp = response.filter(document => document.region_id === regionId)
            const dataObject = dataObjectTemp.filter(item => item.status === 0)
            this.regionDistrictLists = dataObject.map(obj => {
                if (this.$i18n.locale === 'bn') {
                    return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text_bn }
                } else {
                    return { value: obj.district_id, text: this.$store.state.commonObj.districtList.find(document => document.value === obj.district_id).text }
                }
            })
        }
      })
      this.districtLoading = false
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)

      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }

      return upazilaList
    },
    getUnionList (unionId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)

      if (unionId) {
        return unionList.filter(union => union.upazilla_id === unionId)
      }

      return unionList
    },
    getWarehouseName (Id = null, type) {
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
        var list
      if (Id && type === 'UPZ') {
        list = warehouseList.filter(warehouse => warehouse.upazilla_id === Id)
      }

      if (Id && type === 'UP') {
        list = warehouseList.filter(warehouse => warehouse.union_id === Id)
      }
      return list
    },
    getCropList (cropTypeId = null) {
      const cropList = this.$store.state.warehouse.commodityNameList.filter(item => item.status === 0)
      if (cropTypeId) {
        return cropList.filter(document => document.commodity_group_id === cropTypeId)
      }
      return cropList
    },
    getWarehouse (warehouseId) {
      if (warehouseId === null || warehouseId === undefined) {
                return ''
          }
      const warehouseList = this.$store.state.warehouse.warehouseInfoList.filter(item => item.status === 0)
      const warehouseData = warehouseList.find(warehouse => warehouse.value === warehouseId)
      return (this.$i18n.locale === 'bn') ? warehouseData.text_bn : warehouseData.text_en
    },
    getRegion (regionId) {
      if (regionId === null || regionId === undefined) {
                return ''
          }
      const regionList = this.$store.state.warehouse.regionList.filter(item => item.status === 0)
      const regionData = regionList.find(region => region.value === regionId)
      return (this.$i18n.locale === 'bn') ? regionData.text_bn : regionData.text_en
    },
    getUpazila (upazillaId) {
      if (upazillaId === null || upazillaId === undefined) {
                return ''
          }
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      const upazillaData = upazilaList.find(upazilla => upazilla.value === upazillaId)
      return (this.$i18n.locale === 'bn') ? upazillaData.text_bn : upazillaData.text_en
    },
    getDistrict (districtId) {
      if (districtId === null || districtId === undefined) {
                return ''
          }
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      const districtData = districtList.find(district => district.value === districtId)
      return (this.$i18n.locale === 'bn') ? districtData.text_bn : districtData.text_en
    },
    formatData (data) {
      const formattedData = data.map(item => {
        const cropTypeData = this.$store.state.warehouse.commodityGroupList.find(cropType => cropType.value === item.crop_type_id && cropType.status === 0)
        const cropNameData = this.$store.state.warehouse.commodityNameList.find(cropName => cropName.value === item.crop_id && cropName.status === 0)
        const tmpBindingData = {
          commodity_group_name: cropTypeData !== undefined ? cropTypeData.text_en : '',
          commodity_group_name_bn: cropTypeData !== undefined ? cropTypeData.text_bn : '',
          commodity_name: cropNameData !== undefined ? cropNameData.text_en : '',
          commodity_name_bn: cropNameData !== undefined ? cropNameData.text_bn : ''
        }
        return Object.assign({}, item, tmpBindingData)
      })
      return formattedData
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseInfoService.cropType'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseInfoService.cropName'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('warehouseInfoService.quantity'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
        const date = this.currentDate
        const region = this.getRegion(this.heading.region_id)
        const district = this.getDistrict(this.heading.district_id)
        const upazilla = this.getUpazila(this.heading.upazilla_id)
        const warehouse = this.getWarehouse(this.heading.warehouse_id)
        const header = [
          { text: this.$t('warehouseInfoService.date') + ' : ' + date, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' }, { text: '' }
          ]
        const header2 = [
          { text: this.$t('warehouse_config.region') + ' : ' + region, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' },
          { text: this.$t('org_pro_district.district') + ' : ' + district, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [250, 0, 0, 0] }
         ]
       const header3 = [
          { text: this.$t('org_pro_upazilla.upazilla') + ' : ' + upazilla, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }, { text: '' },
          { text: this.$t('warehouse_information.warehouse_name') + ' : ' + warehouse, fontSize: (this.$i18n.locale === 'bn' ? 14 : 12), margin: [250, 0, 0, 0] }
        ]
      const extraData = {
        totalHeaderRows: 2,
        subHead: [header, header2, header3]
      }
      rowData.unshift(labels)
      const reportTitle = this.$i18n.locale === 'en' ? 'Stock Of Product' : 'পণ্যের স্টক'
      const columnWids = ['15%', '25%', '25%', '25%']
      ExportPdf.exportPdf(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, rowData, columnWids, extraData)
    },
    getPdfData () {
        const keys = [
          { key: 'serial_no' },
          { key: this.$i18n.locale === 'en' ? 'commodity_group_name' : 'commodity_group_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'commodity_name' : 'commodity_name_bn' },
          { key: this.$i18n.locale === 'en' ? 'Quantity' : 'Quantity' }
        ]
      var serial = 0
      const listData = this.listData.map(item => {
        serial += 1
        const rowData = keys.map((keyItem, index) => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'cropType') {
                return { text: this.$n(item[keyItem.key]) }
            }
            if (keyItem.key === 'cropName') {
              return { text: this.$n(item[keyItem.key]) }
            }

             if (keyItem.key === 'Quantity') {
              return { text: this.$n(item[keyItem.key]) }
            }

          return { text: item[keyItem.key] }
        })

        return rowData
      })

      return listData
    },
    organizationData () {
      const objectData = this.$store.state.commonObj.organizationProfileList
      const orglist = objectData.find(item => item.value === this.search.org_id)

      RestApi.getData(warehouseServiceBaseUrl, reportHeadingList + '/' + this.search.org_id, { org_id: this.search.org_id }).then(response => {
        if (response.success) {
          const orgObject = response.data
          const orgData = {
            left_logo: orgObject.left_logo,
            right_logo: orgObject.right_logo,
            address: orgObject.address,
            address_bn: orgObject.address_bn,
            project_name: orgObject.project_name,
            project_name_bn: orgObject.project_name_bn
          }
          this.organization = Object.assign({}, orglist, orgData)
        } else {
          this.$toast.error({
            title: 'Error',
            message: 'Organization not found!'
          })
        }
      })
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
